import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import GKTextField from "components/GKTextField";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { brokerSupport } from "utils/preferences";
import { theme } from "utils/Theme";

interface BrokerValues {
  [key: string]: boolean | string;
  adityaBirlaSupport: boolean;
  pmsSupport: boolean;
  isRaAccess: boolean;
  lmSupport: boolean;
  delayExecution: boolean;
  prodco: string;
  firmco: string;
  iiflSupport: boolean;
  isPayUPayment: boolean;
}

interface Props {
  data: any;
  isFetched: boolean;
  refetch: () => void;
}

const BrokerPreference = (props: Props) => {
  const { data, isFetched, refetch } = props;

  const { id } = useParams();
  const [expanded, setExpanded] = useState<number>(-1);

  const { mutate } = useMutation({
    mutationKey: ["UPDATE_BROKER"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/super_admin/${id}/broker_integration_status/`,
        data
      ),
    onSuccess: (response) => {
      refetch();
      toast.success(response?.data?.message || "Broker Update Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  });

  const formik = useFormik<BrokerValues>({
    enableReinitialize: isFetched,
    initialValues: {
      adityaBirlaSupport: data?.data?.data?.adityaBirlaSupport || false,
      pmsSupport: data?.data?.data?.pmsSupport || false,
      isRaAccess: data?.data?.data?.isRaAccess || false,
      lmSupport: data?.data?.data?.lmSupport || false,
      delayExecution: data?.data?.data?.delayExecution || false,
      iiflSupport: data?.data?.data?.iiflSupport || false,
      prodco: data?.data?.data?.prodco || "",
      firmco: data?.data?.data?.firmco || "",
      isPayUPayment: data?.data?.data?.isPayUPayment || false,
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  return (
    <form id="broker-preferences-form" onSubmit={formik.handleSubmit}>
      {brokerSupport.map((dataItem, index) => (
        <Accordion
          key={index}
          disableGutters
          onChange={() => {
            setExpanded(index === expanded ? -1 : index);
          }}
          expanded={index === expanded}
          style={{
            border: `solid 1px ${theme.palette.dark?.main}44`,
            borderRadius: "8px",
            boxShadow: "none",
            background: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              textTransform="none"
              color={theme.palette.primary.main}
            >
              {dataItem.mainHeading}
            </Typography>
            {expanded === 0 && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                form="broker-preferences-form"
                onClick={(e) => e.stopPropagation()}
              >
                Save
              </Button>
            )}
          </AccordionSummary>
          <Divider />
          {dataItem.details.map((data, subIndex) => (
            <AccordionDetails
              key={subIndex}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {data.key === "lmSupport" ? (
                <>
                  <Switch
                    onChange={(event) => {
                      formik.setFieldValue(data.key, event.target.checked);
                      if (!event.target.checked) {
                        formik.setFieldValue("prodco", "");
                        formik.setFieldValue("firmco", "");
                      }
                    }}
                    checked={formik.values[data.key]}
                  />
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    textTransform="none"
                    color={theme.palette.dark?.main}
                  >
                    {data.heading}
                  </Typography>
                </>
              ) : (
                <>
                  <Switch
                    onChange={(event) => {
                      formik.setFieldValue(data.key, event.target.checked);
                    }}
                    checked={formik.values[data.key] === true}
                  />
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    textTransform="none"
                    color={theme.palette.dark?.main}
                  >
                    {data.heading}
                  </Typography>
                </>
              )}
            </AccordionDetails>
          ))}

          <AccordionDetails
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {formik.values.lmSupport && (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <AccordionDetails
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      padding: "10px 4px",
                    }}
                  >
                    <Switch
                      checked={formik.values.delayExecution}
                      onChange={(event) => {
                        mutate({ delayExecution: event.target.checked });
                      }}
                    />
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      textTransform={"none"}
                      color={theme.palette.dark?.main}
                    >
                      {data?.heading || "Delay sell trades to next day"}
                    </Typography>
                  </AccordionDetails>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      name="prodco"
                      inputLabel="Prodco"
                      required
                      value={formik.values.prodco}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      name="firmco"
                      inputLabel="Firmco"
                      required
                      value={formik.values.firmco}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </form>
  );
};

export default BrokerPreference;
